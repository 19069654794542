import { AuthOption } from '@collection-platform-frontend/api';
import { destinationUrl } from '@collection-platform-frontend/shared';

export const authOption: AuthOption = {
  appPageURL: '/collection',
  authPageURL: ({ ctx }) => {
    const destURL = destinationUrl(ctx);
    const pathname = destURL.pathname;
    const ignorePaths = ['/auth/signout', '/setting/email/confirm'];
    if (!pathname || ignorePaths.includes(pathname)) {
      return '/auth';
    }

    return `/auth?callbackUrl=${pathname}`;
  },
  loginAPIEndpoint: '/api/login',
  logoutAPIEndpoint: '/api/logout',
};
