import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
};

export type AchieveCampaignQuizInput = {
  id: Scalars['ID']['input'];
  quizOptionId: Scalars['ID']['input'];
};

export type AchievementPointSum = {
  __typename: 'AchievementPointSum';
  amount: Scalars['Int']['output'];
  campaignId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
};

export type Activity = {
  __typename: 'Activity';
  createdAt: Scalars['DateTime']['output'];
  fromCustomer: Customer;
  fromCustomerId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  nft: Nft;
  nftId: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  toCustomer: Customer;
  toCustomerId: Scalars['ID']['output'];
  type: ActivityType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ActivityConnection = {
  __typename: 'ActivityConnection';
  edges?: Maybe<Array<ActivityEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ActivityEdge = {
  __typename: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ActivityFilterInput = {
  fromCustomerId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  nftId?: InputMaybe<Scalars['ID']['input']>;
  toCustomerId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ActivityType>;
};

export type ActivitySortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
};

export enum ActivityType {
  List = 'LIST',
  Mint = 'MINT',
  Sale = 'SALE',
  Transfer = 'TRANSFER',
}

export type Application = {
  __typename: 'Application';
  contracts: Array<Contract>;
  iconAsset: Asset;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ApplicationFeatureContent = {
  __typename: 'ApplicationFeatureContent';
  applicationId: Scalars['ID']['output'];
  asset?: Maybe<Asset>;
  assetId: Scalars['ID']['output'];
  campaignSlug?: Maybe<Scalars['String']['output']>;
  contentType: ContentType;
  createdAt: Scalars['DateTime']['output'];
  dropSlug?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  externalLink?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  startFrom?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ApplicationFeatureContentEdge = {
  __typename: 'ApplicationFeatureContentEdge';
  cursor: Scalars['String']['output'];
  node: ApplicationFeatureContent;
};

export type ApplicationFeatureContentsConnection = {
  __typename: 'ApplicationFeatureContentsConnection';
  edges?: Maybe<Array<ApplicationFeatureContentEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ApplicationFeatureContentsFilterInput = {
  ContentType?: InputMaybe<ContentType>;
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  campaignSlug?: InputMaybe<Scalars['String']['input']>;
  dropSlug?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ApplicationFeatureContentsSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  expiredAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sortIndex?: InputMaybe<Scalars['String']['input']>;
  startFrom?: InputMaybe<Scalars['String']['input']>;
};

export type Asset = {
  __typename: 'Asset';
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Campaign = {
  __typename: 'Campaign';
  achievementIconName: Scalars['String']['output'];
  achievementPointName: Scalars['String']['output'];
  applicationId: Scalars['ID']['output'];
  backgroundImageAsset?: Maybe<Asset>;
  backgroundImageAssetId: Scalars['ID']['output'];
  bannerImageAsset?: Maybe<Asset>;
  bannerImageAssetId: Scalars['ID']['output'];
  campaignAchievementIcons?: Maybe<Array<CampaignAchievementIcon>>;
  campaignMissions?: Maybe<Array<CampaignMission>>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  profileImageAsset?: Maybe<Asset>;
  profileImageAssetId: Scalars['ID']['output'];
  rewardDescription: Scalars['String']['output'];
  rewardImageAsset?: Maybe<Asset>;
  rewardImageAssetId: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  startAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignAchievementIcon = {
  __typename: 'CampaignAchievementIcon';
  achievementPoint: Scalars['Int']['output'];
  campaignId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  iconNormalImageAsset?: Maybe<Asset>;
  iconNormalImageAssetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignAchievementLog = {
  __typename: 'CampaignAchievementLog';
  achievementPoint: Scalars['Int']['output'];
  campaignId: Scalars['String']['output'];
  campaignMissionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignExchangeableItem = {
  __typename: 'CampaignExchangeableItem';
  achievementPoint: Scalars['Int']['output'];
  bannerImageAsset?: Maybe<Asset>;
  bannerImageAssetId: Scalars['ID']['output'];
  campaignId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  dropId: Scalars['ID']['output'];
  dropItems?: Maybe<Array<Item>>;
  endAt: Scalars['DateTime']['output'];
  exchangedAtByCurrentCustomer?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isExchangedByCurrentCustomer?: Maybe<Scalars['Boolean']['output']>;
  itemId: Scalars['ID']['output'];
  sortIndex: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignExchangeableItemConnection = {
  __typename: 'CampaignExchangeableItemConnection';
  edges?: Maybe<Array<CampaignExchangeableItemEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type CampaignExchangeableItemEdge = {
  __typename: 'CampaignExchangeableItemEdge';
  cursor: Scalars['String']['output'];
  node: CampaignExchangeableItem;
};

export type CampaignExchangeableItemsFilterInput = {
  campaignId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type CampaignExchangeableItemsSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export type CampaignMission = {
  __typename: 'CampaignMission';
  achievementPoint?: Maybe<Scalars['Int']['output']>;
  campaignId: Scalars['ID']['output'];
  campaignMissionTwitterFollowSetting?: Maybe<CampaignMissionTwitterFollowSetting>;
  campaignMissionTwitterRetweetSetting?: Maybe<CampaignMissionTwitterRetweetSetting>;
  campaignMissionTwitterTweetSetting?: Maybe<CampaignMissionTwitterTweetSetting>;
  createdAt: Scalars['DateTime']['output'];
  endAt: Scalars['DateTime']['output'];
  externalContentUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRevealed: Scalars['Boolean']['output'];
  sortIndex: Scalars['Int']['output'];
  startAt: Scalars['DateTime']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CampaignMissionType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignMissionTwitterFollowSetting = {
  __typename: 'CampaignMissionTwitterFollowSetting';
  campaignId: Scalars['ID']['output'];
  campaignMissionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  screenName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignMissionTwitterRetweetSetting = {
  __typename: 'CampaignMissionTwitterRetweetSetting';
  campaignId: Scalars['ID']['output'];
  campaignMissionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  tweetId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignMissionTwitterTweetSetting = {
  __typename: 'CampaignMissionTwitterTweetSetting';
  campaignId: Scalars['ID']['output'];
  campaignMissionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  hashtags?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum CampaignMissionType {
  GeneralOpenExternalUrl = 'GeneralOpenExternalURL',
  GeneralPurchase = 'GeneralPurchase',
  GeneralQuiz = 'GeneralQuiz',
  GeneralRead = 'GeneralRead',
  TwitterFollow = 'TwitterFollow',
  TwitterRetweet = 'TwitterRetweet',
  TwitterTweet = 'TwitterTweet',
}

export type CampaignQuiz = {
  __typename: 'CampaignQuiz';
  achieved: Scalars['Boolean']['output'];
  campaignId: Scalars['ID']['output'];
  campaignMissionId: Scalars['ID']['output'];
  commentary: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageAsset?: Maybe<Asset>;
  imageAssetId?: Maybe<Scalars['ID']['output']>;
  isAnswerOrderRandom: Scalars['Boolean']['output'];
  options: Array<CampaignQuizOption>;
  sortIndex: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignQuizInput = {
  id: Scalars['ID']['input'];
};

export type CampaignQuizOption = {
  __typename: 'CampaignQuizOption';
  campaignQuizId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  isCorrect: Scalars['Boolean']['output'];
  sortIndex: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CampaignQuizzesInput = {
  missionId: Scalars['ID']['input'];
};

export enum ChangeRequestStatus {
  Confirmed = 'CONFIRMED',
  Initialized = 'INITIALIZED',
  Processing = 'PROCESSING',
}

export type CheckAstarSonyGiftCodeAndCreateSignatureInput = {
  giftCode: Scalars['String']['input'];
  recipientAddress: Scalars['String']['input'];
};

export type CheckAstarSonyGiftCodeAndCreateSignaturePayload = {
  __typename: 'CheckAstarSonyGiftCodeAndCreateSignaturePayload';
  hashedGiftCode: Scalars['String']['output'];
  signature: Scalars['String']['output'];
};

export type Checkout = {
  __typename: 'Checkout';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  customerId: Scalars['ID']['output'];
  declineCode?: Maybe<Scalars['String']['output']>;
  dropId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  primaryTaxPrice: Scalars['Int']['output'];
  status: CheckoutStatus;
  totalPrice: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CheckoutPayload = {
  __typename: 'CheckoutPayload';
  status: Scalars['Float']['output'];
  stripeCheckoutId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum CheckoutStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  RequireCapture = 'REQUIRE_CAPTURE',
  Succeeded = 'SUCCEEDED',
}

export enum ContentType {
  CampaignSlug = 'CAMPAIGN_SLUG',
  DropSlug = 'DROP_SLUG',
  ExternalLink = 'EXTERNAL_LINK',
}

export type Contract = {
  __typename: 'Contract';
  address?: Maybe<Scalars['String']['output']>;
  applicationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalDomain?: Maybe<Scalars['String']['output']>;
  externalTransferable: Scalars['Boolean']['output'];
  items: ItemConnection;
  marketplaceListable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ContractItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ItemSortInput;
};

export type ContractConnection = {
  __typename: 'ContractConnection';
  edges?: Maybe<Array<ContractEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ContractDeployed = {
  __typename: 'ContractDeployed';
  contractName: Scalars['String']['output'];
};

export type ContractEdge = {
  __typename: 'ContractEdge';
  cursor: Scalars['String']['output'];
  node: Contract;
};

export type ContractFilterInput = {
  marketplaceListable?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContractSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ContractWithToken = {
  __typename: 'ContractWithToken';
  address?: Maybe<Scalars['String']['output']>;
  applicationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalDomain?: Maybe<Scalars['String']['output']>;
  externalTransferable: Scalars['Boolean']['output'];
  items: ItemConnection;
  marketplaceListable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type ContractWithTokenItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ItemSortInput;
};

export type ContractWithTokenEdge = {
  __typename: 'ContractWithTokenEdge';
  cursor: Scalars['String']['output'];
  node: ContractWithToken;
};

export type ContractsHasDropDto = {
  __typename: 'ContractsHasDropDTO';
  address?: Maybe<Scalars['String']['output']>;
  applicationId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  externalDomain?: Maybe<Scalars['String']['output']>;
  externalTransferable: Scalars['Boolean']['output'];
  items: Array<Item>;
  marketplaceListable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ContractsHasDropFilterInput = {
  applicationId: Scalars['String']['input'];
  isValidPeriod?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Customer = {
  __typename: 'Customer';
  createdAt: Scalars['DateTime']['output'];
  customerIcon?: Maybe<CustomerIcon>;
  customerIconId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<ItemConnection>;
  nfts?: Maybe<NftConnection>;
  nickName: Scalars['String']['output'];
  profileIconUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ItemSortInput;
};

export type CustomerNftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NftFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NftSortInput>;
};

export type CustomerEdge = {
  __typename: 'CustomerEdge';
  cursor: Scalars['String']['output'];
  node: Customer;
};

export type CustomerIcon = {
  __typename: 'CustomerIcon';
  asset: Asset;
  assetId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
};

export type CustomerIconItem = {
  __typename: 'CustomerIconItem';
  assetUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type CustomerUpdatePayload = {
  __typename: 'CustomerUpdatePayload';
  customerIconId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  nickName: Scalars['String']['output'];
};

export type Drop = {
  __typename: 'Drop';
  acquisitionLimitCount?: Maybe<Scalars['Float']['output']>;
  applicationId: Scalars['String']['output'];
  bannerImageAssetId?: Maybe<Scalars['ID']['output']>;
  bannerImageAssetUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  distributionType: Scalars['String']['output'];
  dropItems?: Maybe<Array<DropItem>>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  externalContentUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryQuantity?: Maybe<Scalars['Int']['output']>;
  items: ItemConnection;
  name: Scalars['String']['output'];
  notice?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['ID']['output'];
  startAt?: Maybe<Scalars['DateTime']['output']>;
  thumbnailAssetId?: Maybe<Scalars['ID']['output']>;
  thumbnailAssetUrl?: Maybe<Scalars['String']['output']>;
  totalQuantity?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DropItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ItemSortInput;
};

export type DropConnection = {
  __typename: 'DropConnection';
  edges?: Maybe<Array<DropEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type DropEdge = {
  __typename: 'DropEdge';
  cursor: Scalars['String']['output'];
  node: Drop;
};

export type DropFilterInput = {
  applicationId: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  isExpiredAtGreaterThan?: InputMaybe<Scalars['DateTime']['input']>;
  isStartAtLessThan?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DropItem = {
  __typename: 'DropItem';
  createdAt: Scalars['DateTime']['output'];
  drop?: Maybe<Drop>;
  dropId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DropSortInput = {
  startAt: Scalars['String']['input'];
};

export type EmailChangeRequestPayload = {
  __typename: 'EmailChangeRequestPayload';
  email: Scalars['String']['output'];
  newEmail: Scalars['String']['output'];
  requestStatus: ChangeRequestStatus;
  tokenId?: Maybe<Scalars['String']['output']>;
};

export type ExistCustomerPayload = {
  __typename: 'ExistCustomerPayload';
  exist: Scalars['Boolean']['output'];
};

export type GenerateNftJob = {
  __typename: 'GenerateNftJob';
  contractId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  itemBaseTitle: Scalars['String']['output'];
  itemCopyrights: Scalars['String']['output'];
  itemDescription: Scalars['String']['output'];
  itemExternalDomainViewUrl?: Maybe<Scalars['String']['output']>;
  itemMetadata: Array<ItemMetadata>;
  itemProperties: Array<ItemProperty>;
  itemReleaseAt?: Maybe<Scalars['DateTime']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  publishQuantity: Scalars['Int']['output'];
  unlockableContentsCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Item = {
  __typename: 'Item';
  contractDisplayName: Scalars['String']['output'];
  contractName: Scalars['ID']['output'];
  copyrights: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  dropItems?: Maybe<Array<DropItem>>;
  externalDomainViewUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inventoryQuantity: Scalars['Int']['output'];
  inventoryUnlimited: Scalars['Boolean']['output'];
  isOwned: Scalars['Boolean']['output'];
  lowestPrice?: Maybe<Scalars['Int']['output']>;
  metadata: Array<ItemMetadata>;
  properties: Array<ItemProperty>;
  publicContents: Array<PublicContent>;
  releaseAt?: Maybe<Scalars['DateTime']['output']>;
  thumbnailAssetId: Scalars['ID']['output'];
  thumbnailAssetUrl: Scalars['String']['output'];
  title: Scalars['String']['output'];
  totalQuantity: Scalars['Int']['output'];
  unlockableContents: Array<UnlockableContent>;
};

export type ItemConnection = {
  __typename: 'ItemConnection';
  edges?: Maybe<Array<ItemEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type ItemCreated = {
  __typename: 'ItemCreated';
  itemId: Scalars['ID']['output'];
};

export type ItemEdge = {
  __typename: 'ItemEdge';
  cursor: Scalars['String']['output'];
  node: Item;
};

export type ItemExchangeLog = {
  __typename: 'ItemExchangeLog';
  achievementPoint: Scalars['Int']['output'];
  campaignExchangeableItemId: Scalars['ID']['output'];
  campaignId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerId: Scalars['ID']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dropId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  itemId: Scalars['ID']['output'];
  operationId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ItemExchangeLogsPointSum = {
  __typename: 'ItemExchangeLogsPointSum';
  amount: Scalars['Int']['output'];
  campaignId: Scalars['ID']['output'];
  customerId: Scalars['ID']['output'];
};

export type ItemFilterInput = {
  contractName?: InputMaybe<Scalars['String']['input']>;
  flowId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isIncludeNotReleased?: InputMaybe<Scalars['Boolean']['input']>;
  setId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ItemMetadata = {
  __typename: 'ItemMetadata';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ItemMetadataInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ItemMinted = {
  __typename: 'ItemMinted';
  fromSerialNumber: Scalars['Int']['output'];
  itemId: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  toSerialNumber: Scalars['Int']['output'];
};

export type ItemProperty = {
  __typename: 'ItemProperty';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ItemPropertyInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ItemSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  flowId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lowestPrice?: InputMaybe<Scalars['String']['input']>;
};

export type MaintenanceSchedule = {
  __typename: 'MaintenanceSchedule';
  endAt: Scalars['DateTime']['output'];
  startAt: Scalars['DateTime']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  achieveCampaignMission: CampaignAchievementLog;
  achieveCampaignMissionByMissionId: CampaignAchievementLog;
  achieveCampaignQuiz: CampaignQuiz;
  assignExchangeableItemToCustomer: ItemExchangeLog;
  checkAstarSonyGiftCodeAndCreateSignature: CheckAstarSonyGiftCodeAndCreateSignaturePayload;
  confirmUpdateEmail: EmailChangeRequestPayload;
  createConnectAccount: StripeConnectAccountPayload;
  customerProfileUpdate: CustomerUpdatePayload;
  itemTransferByDrop: Operation;
  itemTransferByGiftCode: Operation;
  marketplaceCancel: SaleNft;
  marketplaceList: SaleNftPayload;
  requestChangeEmail: EmailChangeRequestPayload;
  sessionCreateByDrop: CheckoutPayload;
  sessionCreateByDropForLoadTest: CheckoutPayload;
  sessionCreateBySaleNft: CheckoutPayload;
  signInWithEmailLink: SignInWithEmailLinkPayload;
  signUp: SignUpPayload;
  signUpAsLoadtestCustomer: SignUpPayload;
  verifyChangeEmail: EmailChangeRequestPayload;
};

export type MutationAchieveCampaignMissionArgs = {
  achievementCode: Scalars['String']['input'];
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
};

export type MutationAchieveCampaignMissionByMissionIdArgs = {
  applicationId: Scalars['ID']['input'];
  campaignMissionId: Scalars['ID']['input'];
};

export type MutationAchieveCampaignQuizArgs = {
  input: AchieveCampaignQuizInput;
};

export type MutationAssignExchangeableItemToCustomerArgs = {
  applicationId: Scalars['ID']['input'];
  campaignExchangeableItemId: Scalars['ID']['input'];
};

export type MutationCheckAstarSonyGiftCodeAndCreateSignatureArgs = {
  input: CheckAstarSonyGiftCodeAndCreateSignatureInput;
};

export type MutationConfirmUpdateEmailArgs = {
  tokenId: Scalars['String']['input'];
};

export type MutationCustomerProfileUpdateArgs = {
  customerIconId: Scalars['String']['input'];
  nickName: Scalars['String']['input'];
};

export type MutationItemTransferByDropArgs = {
  applicationId: Scalars['String']['input'];
  dropId: Scalars['String']['input'];
};

export type MutationItemTransferByGiftCodeArgs = {
  applicationId: Scalars['String']['input'];
  dropId: Scalars['String']['input'];
  giftCode: Scalars['String']['input'];
};

export type MutationMarketplaceCancelArgs = {
  saleNftId: Scalars['ID']['input'];
};

export type MutationMarketplaceListArgs = {
  saleNftListInput: SaleNftListInput;
};

export type MutationRequestChangeEmailArgs = {
  callbackUrl: Scalars['String']['input'];
  newEmail: Scalars['String']['input'];
};

export type MutationSessionCreateByDropArgs = {
  input: SessionCreateByDropInput;
};

export type MutationSessionCreateByDropForLoadTestArgs = {
  customerId: Scalars['ID']['input'];
  input: SessionCreateByDropInput;
};

export type MutationSessionCreateBySaleNftArgs = {
  saleNftId: Scalars['ID']['input'];
};

export type MutationSignInWithEmailLinkArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
  callbackUrl: Scalars['String']['input'];
  email: Scalars['String']['input'];
  reCaptchaToken?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSignUpArgs = {
  input: SignUpInput;
};

export type MutationSignUpAsLoadtestCustomerArgs = {
  input: SignUpAsLoadtestCustomerInput;
};

export type MutationVerifyChangeEmailArgs = {
  callbackUrl: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type MyCustomerApplication = {
  __typename: 'MyCustomerApplication';
  applicationId: Scalars['ID']['output'];
};

export type MyCustomerPayload = {
  __typename: 'MyCustomerPayload';
  applicationCustomer?: Maybe<MyCustomerApplication>;
  customerIconId: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  nickName: Scalars['String']['output'];
  profileIconUrl: Scalars['String']['output'];
  wallet?: Maybe<Wallet>;
};

export type News = {
  __typename: 'News';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type NewsConnection = {
  __typename: 'NewsConnection';
  edges?: Maybe<Array<NewsEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type NewsEdge = {
  __typename: 'NewsEdge';
  cursor: Scalars['String']['output'];
  node: News;
};

export type NewsSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type Nft = {
  __typename: 'Nft';
  contractDisplayName: Scalars['String']['output'];
  contractName: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isListing?: Maybe<Scalars['Boolean']['output']>;
  isOwned?: Maybe<Scalars['Boolean']['output']>;
  item?: Maybe<Item>;
  itemId: Scalars['ID']['output'];
  ownerAddress?: Maybe<Scalars['String']['output']>;
  saleNfts: SaleNftConnection;
  serialNumber?: Maybe<Scalars['Int']['output']>;
  tokenId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NftSaleNftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SaleNftFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SaleNftSortInput>;
};

export type NftConnection = {
  __typename: 'NftConnection';
  edges?: Maybe<Array<NftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type NftEdge = {
  __typename: 'NftEdge';
  cursor: Scalars['String']['output'];
  node: Nft;
};

export type NftFilterInput = {
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  contractName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isIncludeNftSyncing?: InputMaybe<Scalars['Boolean']['input']>;
  itemId?: InputMaybe<Scalars['ID']['input']>;
  ownerWalletAddress?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NftSortInput = {
  contractName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  itemId?: InputMaybe<Scalars['String']['input']>;
  ownerWalletAddress?: InputMaybe<Scalars['String']['input']>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type NftTransferred = {
  __typename: 'NftTransferred';
  nftIds: Array<Scalars['ID']['output']>;
  walletId: Scalars['ID']['output'];
};

export type Operation = {
  __typename: 'Operation';
  applicationId: Scalars['String']['output'];
  contentableId: Scalars['String']['output'];
  contractName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  option?: Maybe<OperationOptionUnion>;
  status: Scalars['String']['output'];
};

export type OperationEdge = {
  __typename: 'OperationEdge';
  cursor: Scalars['String']['output'];
  node: Operation;
};

export type OperationOptionUnion =
  | ContractDeployed
  | ItemCreated
  | ItemMinted
  | NftTransferred
  | WalletSetup;

export type Order = {
  __typename: 'Order';
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  customerId: Scalars['ID']['output'];
  dropId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  operation: Operation;
  orderType: OrderType;
  primaryTaxPrice: Scalars['Int']['output'];
  totalPrice: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum OrderType {
  Drop = 'DROP',
}

export type PageInfo = {
  __typename: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PongPayload = {
  __typename: 'PongPayload';
  status: Scalars['String']['output'];
};

export type PrivateAsset = {
  __typename: 'PrivateAsset';
  createdAt: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PublicContent = {
  __typename: 'PublicContent';
  backgroundPublicAsset?: Maybe<Asset>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  publicAsset: Asset;
  updatedAt: Scalars['DateTime']['output'];
};

export type Query = {
  __typename: 'Query';
  achievementPointSum: AchievementPointSum;
  activities: ActivityConnection;
  application: Application;
  applicationFeatureContents: ApplicationFeatureContentsConnection;
  campaign: Campaign;
  campaignAchievementLogs: Array<CampaignAchievementLog>;
  campaignExchangeableItems: CampaignExchangeableItemConnection;
  campaignQuiz: CampaignQuiz;
  campaignQuizzes: Array<CampaignQuiz>;
  checkout: Checkout;
  contract: Contract;
  contractHasDrop: ContractsHasDropDto;
  contracts: ContractConnection;
  contractsHasDrop: Array<ContractsHasDropDto>;
  customer: Customer;
  customerIcons: Array<CustomerIconItem>;
  drop: Drop;
  drops: DropConnection;
  existCustomer: ExistCustomerPayload;
  itemExchangeLogsPointSum: ItemExchangeLogsPointSum;
  items: ItemConnection;
  myCustomer: MyCustomerPayload;
  myNfts: NftConnection;
  myWallet: Wallet;
  news: NewsConnection;
  nft: Nft;
  nfts: NftConnection;
  ping: PongPayload;
  scheduledMaintenancesForFuture: Array<MaintenanceSchedule>;
};

export type QueryAchievementPointSumArgs = {
  campaignId: Scalars['ID']['input'];
};

export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ActivitySortInput>;
};

export type QueryApplicationArgs = {
  applicationId: Scalars['String']['input'];
};

export type QueryApplicationFeatureContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ApplicationFeatureContentsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ApplicationFeatureContentsSortInput>;
};

export type QueryCampaignArgs = {
  applicationId: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

export type QueryCampaignAchievementLogsArgs = {
  slug: Scalars['String']['input'];
};

export type QueryCampaignExchangeableItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CampaignExchangeableItemsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<CampaignExchangeableItemsSortInput>;
};

export type QueryCampaignQuizArgs = {
  input: CampaignQuizInput;
};

export type QueryCampaignQuizzesArgs = {
  input: CampaignQuizzesInput;
};

export type QueryCheckoutArgs = {
  id: Scalars['String']['input'];
};

export type QueryContractArgs = {
  name: Scalars['String']['input'];
};

export type QueryContractHasDropArgs = {
  applicationId: Scalars['ID']['input'];
  contractName: Scalars['String']['input'];
};

export type QueryContractsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ContractFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ContractSortInput;
};

export type QueryContractsHasDropArgs = {
  filter: ContractsHasDropFilterInput;
};

export type QueryCustomerArgs = {
  id: Scalars['String']['input'];
};

export type QueryDropArgs = {
  applicationId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDropsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: DropFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy: DropSortInput;
};

export type QueryExistCustomerArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryItemExchangeLogsPointSumArgs = {
  campaignId: Scalars['ID']['input'];
};

export type QueryItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ItemFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: ItemSortInput;
};

export type QueryMyCustomerArgs = {
  applicationId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMyNftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NftFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NftSortInput>;
};

export type QueryNewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: NewsSortInput;
};

export type QueryNftArgs = {
  id: Scalars['String']['input'];
};

export type QueryNftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NftFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NftSortInput>;
};

export type SaleNft = {
  __typename: 'SaleNft';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  listCustomer: Customer;
  listCustomerId: Scalars['ID']['output'];
  listedAt: Scalars['DateTime']['output'];
  nft: Nft;
  nftId: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  purchaseCustomer?: Maybe<Customer>;
  purchaseCustomerId?: Maybe<Scalars['ID']['output']>;
  purchasedAt?: Maybe<Scalars['DateTime']['output']>;
  status: SaleNftStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type SaleNftConnection = {
  __typename: 'SaleNftConnection';
  edges?: Maybe<Array<SaleNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars['Int']['output'];
};

export type SaleNftEdge = {
  __typename: 'SaleNftEdge';
  cursor: Scalars['String']['output'];
  node: SaleNft;
};

export type SaleNftFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lowerLimitPrice?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  upperLimitPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type SaleNftListInput = {
  nftId: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
};

export type SaleNftPayload = {
  __typename: 'SaleNftPayload';
  SaleNft: SaleNft;
  status: Scalars['Float']['output'];
};

export type SaleNftSortInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
};

export enum SaleNftStatus {
  Canceled = 'CANCELED',
  Listing = 'LISTING',
  Sold = 'SOLD',
}

export type SessionCreateByDropInput = {
  dropId: Scalars['ID']['input'];
  purchaseCancelUrl: Scalars['String']['input'];
  purchaseSuccessUrl: Scalars['String']['input'];
};

export type SignInWithEmailLinkPayload = {
  __typename: 'SignInWithEmailLinkPayload';
  email: Scalars['String']['output'];
};

export type SignUpAsLoadtestCustomerInput = {
  applicationId: Scalars['ID']['input'];
};

export type SignUpInput = {
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  idToken: Scalars['String']['input'];
};

export type SignUpPayload = {
  __typename: 'SignUpPayload';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  operation?: Maybe<Operation>;
};

export type StripeConnectAccountPayload = {
  __typename: 'StripeConnectAccountPayload';
  created: Scalars['Float']['output'];
  expires_at: Scalars['Float']['output'];
  isCreated: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UnlockableContent = {
  __typename: 'UnlockableContent';
  backgroundPrivateAsset?: Maybe<PrivateAsset>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  privateAsset?: Maybe<PrivateAsset>;
  sortIndex: Scalars['Int']['output'];
  thumbnailPrivateAsset?: Maybe<PrivateAsset>;
  unlocked: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Wallet = {
  __typename: 'Wallet';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nfts: NftConnection;
};

export type WalletNftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<NftFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<NftSortInput>;
};

export type WalletEdge = {
  __typename: 'WalletEdge';
  cursor: Scalars['String']['output'];
  node: Wallet;
};

export type WalletSetup = {
  __typename: 'WalletSetup';
  walletId: Scalars['ID']['output'];
};

export type GetApplicationQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type GetApplicationQuery = {
  __typename: 'Query';
  application: {
    __typename: 'Application';
    name: string;
    iconAsset: { __typename: 'Asset'; id: string; url: string };
  };
};

export type GetCampaignDetailQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  applicationId: Scalars['String']['input'];
}>;

export type GetCampaignDetailQuery = {
  __typename: 'Query';
  campaign: {
    __typename: 'Campaign';
    id: string;
    title: string;
    description: string;
    rewardDescription: string;
    achievementPointName: string;
    achievementIconName: string;
    startAt: any;
    endAt: any;
    profileImageAsset?: { __typename: 'Asset'; url: string } | null;
    backgroundImageAsset?: { __typename: 'Asset'; url: string } | null;
    bannerImageAsset?: { __typename: 'Asset'; url: string } | null;
    rewardImageAsset?: { __typename: 'Asset'; url: string } | null;
    campaignMissions?: Array<{
      __typename: 'CampaignMission';
      id: string;
      isRevealed: boolean;
      title?: string | null;
      sortIndex: number;
      type?: CampaignMissionType | null;
      achievementPoint?: number | null;
      startAt: any;
      endAt: any;
      externalContentUrl?: string | null;
      campaignId: string;
      campaignMissionTwitterFollowSetting?: {
        __typename: 'CampaignMissionTwitterFollowSetting';
        screenName: string;
      } | null;
      campaignMissionTwitterTweetSetting?: {
        __typename: 'CampaignMissionTwitterTweetSetting';
        text: string;
        url?: string | null;
        hashtags?: string | null;
      } | null;
      campaignMissionTwitterRetweetSetting?: {
        __typename: 'CampaignMissionTwitterRetweetSetting';
        tweetId: string;
      } | null;
    }> | null;
    campaignAchievementIcons?: Array<{
      __typename: 'CampaignAchievementIcon';
      id: string;
      sortIndex: number;
      title: string;
      description: string;
      achievementPoint: number;
      iconNormalImageAsset?: { __typename: 'Asset'; url: string } | null;
    }> | null;
  };
};

export type GetCampaignAchievementLogsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetCampaignAchievementLogsQuery = {
  __typename: 'Query';
  campaignAchievementLogs: Array<{
    __typename: 'CampaignAchievementLog';
    id: string;
    createdAt: any;
    achievementPoint: number;
    campaignMissionId: string;
  }>;
};

export type GetCampaignQuizQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetCampaignQuizQuery = {
  __typename: 'Query';
  campaignQuiz: {
    __typename: 'CampaignQuiz';
    id: string;
    text: string;
    commentary: string;
    options: Array<{
      __typename: 'CampaignQuizOption';
      id: string;
      text: string;
      isCorrect: boolean;
    }>;
    imageAsset?: { __typename: 'Asset'; url: string } | null;
  };
};

export type GetCampaignExchangeableItemsQueryVariables = Exact<{
  campaignId: Scalars['ID']['input'];
}>;

export type GetCampaignExchangeableItemsQuery = {
  __typename: 'Query';
  campaignExchangeableItems: {
    __typename: 'CampaignExchangeableItemConnection';
    edges?: Array<{
      __typename: 'CampaignExchangeableItemEdge';
      node: {
        __typename: 'CampaignExchangeableItem';
        id: string;
        itemId: string;
        achievementPoint: number;
        isExchangedByCurrentCustomer?: boolean | null;
        exchangedAtByCurrentCustomer?: any | null;
        description: string;
        title: string;
        startAt: any;
        endAt: any;
        sortIndex: number;
        bannerImageAsset?: { __typename: 'Asset'; url: string } | null;
        dropItems?: Array<{
          __typename: 'Item';
          id: string;
          title: string;
          description: string;
          thumbnailAssetUrl: string;
        }> | null;
      };
    }> | null;
  };
};

export type GetCampaignQuizzesQueryVariables = Exact<{
  missionId: Scalars['ID']['input'];
}>;

export type GetCampaignQuizzesQuery = {
  __typename: 'Query';
  campaignQuizzes: Array<{
    __typename: 'CampaignQuiz';
    id: string;
    title: string;
    achieved: boolean;
  }>;
};

export type AchieveCampaignQuizMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  optionId: Scalars['ID']['input'];
}>;

export type AchieveCampaignQuizMutation = {
  __typename: 'Mutation';
  achieveCampaignQuiz: {
    __typename: 'CampaignQuiz';
    id: string;
    achieved: boolean;
  };
};

export type AchieveCampaignMissionByMissionIdMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  missionId: Scalars['ID']['input'];
}>;

export type AchieveCampaignMissionByMissionIdMutation = {
  __typename: 'Mutation';
  achieveCampaignMissionByMissionId: {
    __typename: 'CampaignAchievementLog';
    id: string;
    createdAt: any;
    achievementPoint: number;
    campaignMissionId: string;
  };
};

export type GetItemExchangeLogsPointSumQueryVariables = Exact<{
  campaignId: Scalars['ID']['input'];
}>;

export type GetItemExchangeLogsPointSumQuery = {
  __typename: 'Query';
  itemExchangeLogsPointSum: {
    __typename: 'ItemExchangeLogsPointSum';
    amount: number;
  };
};

export type AchieveCampaignMissionMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  campaignId: Scalars['ID']['input'];
  achievementCode: Scalars['String']['input'];
}>;

export type AchieveCampaignMissionMutation = {
  __typename: 'Mutation';
  achieveCampaignMission: {
    __typename: 'CampaignAchievementLog';
    id: string;
    createdAt: any;
    achievementPoint: number;
    campaignMissionId: string;
  };
};

export type AssignExchangeableItemMutationVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  campaignExchangeableItemId: Scalars['ID']['input'];
}>;

export type AssignExchangeableItemMutation = {
  __typename: 'Mutation';
  assignExchangeableItemToCustomer: {
    __typename: 'ItemExchangeLog';
    id: string;
    achievementPoint: number;
    dropId: string;
    itemId: string;
  };
};

export type CampaignDetailFragment = {
  __typename: 'Campaign';
  id: string;
  title: string;
  description: string;
  rewardDescription: string;
  achievementPointName: string;
  achievementIconName: string;
  startAt: any;
  endAt: any;
  profileImageAsset?: { __typename: 'Asset'; url: string } | null;
  backgroundImageAsset?: { __typename: 'Asset'; url: string } | null;
  bannerImageAsset?: { __typename: 'Asset'; url: string } | null;
  rewardImageAsset?: { __typename: 'Asset'; url: string } | null;
  campaignMissions?: Array<{
    __typename: 'CampaignMission';
    id: string;
    isRevealed: boolean;
    title?: string | null;
    sortIndex: number;
    type?: CampaignMissionType | null;
    achievementPoint?: number | null;
    startAt: any;
    endAt: any;
    externalContentUrl?: string | null;
    campaignId: string;
    campaignMissionTwitterFollowSetting?: {
      __typename: 'CampaignMissionTwitterFollowSetting';
      screenName: string;
    } | null;
    campaignMissionTwitterTweetSetting?: {
      __typename: 'CampaignMissionTwitterTweetSetting';
      text: string;
      url?: string | null;
      hashtags?: string | null;
    } | null;
    campaignMissionTwitterRetweetSetting?: {
      __typename: 'CampaignMissionTwitterRetweetSetting';
      tweetId: string;
    } | null;
  }> | null;
  campaignAchievementIcons?: Array<{
    __typename: 'CampaignAchievementIcon';
    id: string;
    sortIndex: number;
    title: string;
    description: string;
    achievementPoint: number;
    iconNormalImageAsset?: { __typename: 'Asset'; url: string } | null;
  }> | null;
};

export type CampaignAchievementLogListFragment = {
  __typename: 'CampaignAchievementLog';
  id: string;
  createdAt: any;
  achievementPoint: number;
  campaignMissionId: string;
};

export type CampaignMissionListFragment = {
  __typename: 'CampaignMission';
  id: string;
  isRevealed: boolean;
  title?: string | null;
  sortIndex: number;
  type?: CampaignMissionType | null;
  achievementPoint?: number | null;
  startAt: any;
  endAt: any;
  externalContentUrl?: string | null;
  campaignId: string;
  campaignMissionTwitterFollowSetting?: {
    __typename: 'CampaignMissionTwitterFollowSetting';
    screenName: string;
  } | null;
  campaignMissionTwitterTweetSetting?: {
    __typename: 'CampaignMissionTwitterTweetSetting';
    text: string;
    url?: string | null;
    hashtags?: string | null;
  } | null;
  campaignMissionTwitterRetweetSetting?: {
    __typename: 'CampaignMissionTwitterRetweetSetting';
    tweetId: string;
  } | null;
};

export type CampaignAchievementIconListFragment = {
  __typename: 'CampaignAchievementIcon';
  id: string;
  sortIndex: number;
  title: string;
  description: string;
  achievementPoint: number;
  iconNormalImageAsset?: { __typename: 'Asset'; url: string } | null;
};

export type CampaignExchangeableItemListFragment = {
  __typename: 'CampaignExchangeableItem';
  id: string;
  itemId: string;
  achievementPoint: number;
  isExchangedByCurrentCustomer?: boolean | null;
  exchangedAtByCurrentCustomer?: any | null;
  description: string;
  title: string;
  startAt: any;
  endAt: any;
  sortIndex: number;
  bannerImageAsset?: { __typename: 'Asset'; url: string } | null;
  dropItems?: Array<{
    __typename: 'Item';
    id: string;
    title: string;
    description: string;
    thumbnailAssetUrl: string;
  }> | null;
};

export type GetNftsQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetNftsQuery = {
  __typename: 'Query';
  myNfts: {
    __typename: 'NftConnection';
    edges?: Array<{
      __typename: 'NftEdge';
      node: {
        __typename: 'Nft';
        id: string;
        serialNumber?: number | null;
        contractName: string;
        contractDisplayName: string;
        updatedAt: any;
        item?: {
          __typename: 'Item';
          id: string;
          title: string;
          description: string;
          thumbnailAssetUrl: string;
        } | null;
      };
    }> | null;
    pageInfo?: {
      __typename: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    } | null;
  };
};

export type GetNftQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetNftQuery = {
  __typename: 'Query';
  nft: {
    __typename: 'Nft';
    id: string;
    serialNumber?: number | null;
    contractName: string;
    contractDisplayName: string;
    updatedAt: any;
    item?: {
      __typename: 'Item';
      id: string;
      title: string;
      description: string;
      thumbnailAssetUrl: string;
    } | null;
  };
};

export type GetMyNftDetailQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
}>;

export type GetMyNftDetailQuery = {
  __typename: 'Query';
  myNfts: {
    __typename: 'NftConnection';
    edges?: Array<{
      __typename: 'NftEdge';
      node: {
        __typename: 'Nft';
        id: string;
        customerId?: string | null;
        serialNumber?: number | null;
        contractName: string;
        contractDisplayName: string;
        item?: {
          __typename: 'Item';
          id: string;
          title: string;
          description: string;
          thumbnailAssetUrl: string;
          copyrights: string;
          inventoryQuantity: number;
          totalQuantity: number;
          externalDomainViewUrl?: string | null;
          properties: Array<{
            __typename: 'ItemProperty';
            name: string;
            value: string;
          }>;
          metadata: Array<{
            __typename: 'ItemMetadata';
            name: string;
            value: string;
          }>;
          unlockableContents: Array<{
            __typename: 'UnlockableContent';
            id: string;
            unlocked: boolean;
            sortIndex: number;
            createdAt: any;
            privateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
            thumbnailPrivateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
            backgroundPrivateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
          }>;
          publicContents: Array<{
            __typename: 'PublicContent';
            id: string;
            createdAt: any;
            backgroundPublicAsset?: {
              __typename: 'Asset';
              mimeType: string;
              url: string;
            } | null;
            publicAsset: { __typename: 'Asset'; mimeType: string; url: string };
          }>;
        } | null;
      };
    }> | null;
  };
};

export type GetNftDetailQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
}>;

export type GetNftDetailQuery = {
  __typename: 'Query';
  nfts: {
    __typename: 'NftConnection';
    edges?: Array<{
      __typename: 'NftEdge';
      node: {
        __typename: 'Nft';
        id: string;
        customerId?: string | null;
        serialNumber?: number | null;
        contractName: string;
        contractDisplayName: string;
        item?: {
          __typename: 'Item';
          id: string;
          title: string;
          description: string;
          thumbnailAssetUrl: string;
          copyrights: string;
          inventoryQuantity: number;
          totalQuantity: number;
          externalDomainViewUrl?: string | null;
          properties: Array<{
            __typename: 'ItemProperty';
            name: string;
            value: string;
          }>;
          metadata: Array<{
            __typename: 'ItemMetadata';
            name: string;
            value: string;
          }>;
          unlockableContents: Array<{
            __typename: 'UnlockableContent';
            id: string;
            unlocked: boolean;
            sortIndex: number;
            createdAt: any;
            privateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
            thumbnailPrivateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
            backgroundPrivateAsset?: {
              __typename: 'PrivateAsset';
              mimeType: string;
              url?: string | null;
            } | null;
          }>;
          publicContents: Array<{
            __typename: 'PublicContent';
            id: string;
            createdAt: any;
            backgroundPublicAsset?: {
              __typename: 'Asset';
              mimeType: string;
              url: string;
            } | null;
            publicAsset: { __typename: 'Asset'; mimeType: string; url: string };
          }>;
        } | null;
      };
    }> | null;
  };
};

export type NftListFragment = {
  __typename: 'Nft';
  id: string;
  serialNumber?: number | null;
  contractName: string;
  contractDisplayName: string;
  updatedAt: any;
  item?: {
    __typename: 'Item';
    id: string;
    title: string;
    description: string;
    thumbnailAssetUrl: string;
  } | null;
};

export type NftDetailFragment = {
  __typename: 'Nft';
  id: string;
  customerId?: string | null;
  serialNumber?: number | null;
  contractName: string;
  contractDisplayName: string;
  item?: {
    __typename: 'Item';
    id: string;
    title: string;
    description: string;
    thumbnailAssetUrl: string;
    copyrights: string;
    inventoryQuantity: number;
    totalQuantity: number;
    externalDomainViewUrl?: string | null;
    properties: Array<{
      __typename: 'ItemProperty';
      name: string;
      value: string;
    }>;
    metadata: Array<{
      __typename: 'ItemMetadata';
      name: string;
      value: string;
    }>;
    unlockableContents: Array<{
      __typename: 'UnlockableContent';
      id: string;
      unlocked: boolean;
      sortIndex: number;
      createdAt: any;
      privateAsset?: {
        __typename: 'PrivateAsset';
        mimeType: string;
        url?: string | null;
      } | null;
      thumbnailPrivateAsset?: {
        __typename: 'PrivateAsset';
        mimeType: string;
        url?: string | null;
      } | null;
      backgroundPrivateAsset?: {
        __typename: 'PrivateAsset';
        mimeType: string;
        url?: string | null;
      } | null;
    }>;
    publicContents: Array<{
      __typename: 'PublicContent';
      id: string;
      createdAt: any;
      backgroundPublicAsset?: {
        __typename: 'Asset';
        mimeType: string;
        url: string;
      } | null;
      publicAsset: { __typename: 'Asset'; mimeType: string; url: string };
    }>;
  } | null;
};

export type GetContractsHasDropQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
}>;

export type GetContractsHasDropQuery = {
  __typename: 'Query';
  contractsHasDrop: Array<{
    __typename: 'ContractsHasDropDTO';
    name: string;
    displayName: string;
    items: Array<{
      __typename: 'Item';
      id: string;
      dropItems?: Array<{
        __typename: 'DropItem';
        drop?: {
          __typename: 'Drop';
          name: string;
          price?: number | null;
          distributionType: string;
          description?: string | null;
          slug: string;
          startAt?: any | null;
          expiredAt?: any | null;
          externalContentUrl?: string | null;
          thumbnailAssetUrl?: string | null;
          bannerImageAssetUrl?: string | null;
        } | null;
      }> | null;
    }>;
  }>;
};

export type GetDropsByContractQueryVariables = Exact<{
  applicationId: Scalars['ID']['input'];
  contractName: Scalars['String']['input'];
}>;

export type GetDropsByContractQuery = {
  __typename: 'Query';
  contractHasDrop: {
    __typename: 'ContractsHasDropDTO';
    name: string;
    displayName: string;
    applicationId: string;
    items: Array<{
      __typename: 'Item';
      id: string;
      dropItems?: Array<{
        __typename: 'DropItem';
        drop?: {
          __typename: 'Drop';
          name: string;
          price?: number | null;
          distributionType: string;
          description?: string | null;
          slug: string;
          startAt?: any | null;
          expiredAt?: any | null;
          externalContentUrl?: string | null;
          thumbnailAssetUrl?: string | null;
          bannerImageAssetUrl?: string | null;
        } | null;
      }> | null;
    }>;
  };
};

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['String']['input'];
}>;

export type GetCustomerQuery = {
  __typename: 'Query';
  customer: {
    __typename: 'Customer';
    id: string;
    email: string;
    nickName: string;
    profileIconUrl?: string | null;
  };
};

export type IsExistCustomerQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type IsExistCustomerQuery = {
  __typename: 'Query';
  existCustomer: { __typename: 'ExistCustomerPayload'; exist: boolean };
};

export type GetMyCustomerQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMyCustomerQuery = {
  __typename: 'Query';
  myCustomer: {
    __typename: 'MyCustomerPayload';
    id: string;
    email: string;
    nickName: string;
    profileIconUrl: string;
    customerIconId: string;
    wallet?: {
      __typename: 'Wallet';
      id: string;
      address?: string | null;
    } | null;
  };
};

export type GetCustomerIconsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomerIconsQuery = {
  __typename: 'Query';
  customerIcons: Array<{
    __typename: 'CustomerIconItem';
    id: string;
    assetUrl: string;
  }>;
};

export type MyWalletQueryVariables = Exact<{ [key: string]: never }>;

export type MyWalletQuery = {
  __typename: 'Query';
  myWallet: { __typename: 'Wallet'; id: string; address?: string | null };
};

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;

export type SignUpMutation = {
  __typename: 'Mutation';
  signUp: { __typename: 'SignUpPayload'; id: string; email: string };
};

export type SignInWithEmailLinkMutationVariables = Exact<{
  email: Scalars['String']['input'];
  reCaptchaToken: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
  applicationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SignInWithEmailLinkMutation = {
  __typename: 'Mutation';
  signInWithEmailLink: {
    __typename: 'SignInWithEmailLinkPayload';
    email: string;
  };
};

export type CustomerProfileUpdateMutationVariables = Exact<{
  customerIconId: Scalars['String']['input'];
  nickName: Scalars['String']['input'];
}>;

export type CustomerProfileUpdateMutation = {
  __typename: 'Mutation';
  customerProfileUpdate: { __typename: 'CustomerUpdatePayload'; id: string };
};

export type RequestChangeEmailMutationVariables = Exact<{
  newEmail: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
}>;

export type RequestChangeEmailMutation = {
  __typename: 'Mutation';
  requestChangeEmail: {
    __typename: 'EmailChangeRequestPayload';
    email: string;
    newEmail: string;
    requestStatus: ChangeRequestStatus;
  };
};

export type VerifyChangeEmailMutationVariables = Exact<{
  tokenId: Scalars['String']['input'];
  callbackUrl: Scalars['String']['input'];
}>;

export type VerifyChangeEmailMutation = {
  __typename: 'Mutation';
  verifyChangeEmail: {
    __typename: 'EmailChangeRequestPayload';
    email: string;
    newEmail: string;
    requestStatus: ChangeRequestStatus;
  };
};

export type ConfirmUpdateEmailMutationVariables = Exact<{
  tokenId: Scalars['String']['input'];
}>;

export type ConfirmUpdateEmailMutation = {
  __typename: 'Mutation';
  confirmUpdateEmail: {
    __typename: 'EmailChangeRequestPayload';
    email: string;
    newEmail: string;
    requestStatus: ChangeRequestStatus;
  };
};

export type GetDropQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
  dropId?: InputMaybe<Scalars['String']['input']>;
  applicationId: Scalars['String']['input'];
}>;

export type GetDropQuery = {
  __typename: 'Query';
  drop: {
    __typename: 'Drop';
    id: string;
    name: string;
    slug: string;
    distributionType: string;
    thumbnailAssetUrl?: string | null;
    price?: number | null;
    inventoryQuantity?: number | null;
    totalQuantity?: number | null;
    acquisitionLimitCount?: number | null;
    description?: string | null;
    notice?: string | null;
    expiredAt?: any | null;
    items: {
      __typename: 'ItemConnection';
      edges?: Array<{
        __typename: 'ItemEdge';
        node: {
          __typename: 'Item';
          id: string;
          title: string;
          thumbnailAssetUrl: string;
        };
      }> | null;
    };
  };
};

export type CheckoutQueryVariables = Exact<{
  checkoutId: Scalars['String']['input'];
}>;

export type CheckoutQuery = {
  __typename: 'Query';
  checkout: {
    __typename: 'Checkout';
    id: string;
    customerId: string;
    dropId: string;
    status: CheckoutStatus;
    declineCode?: string | null;
    message?: string | null;
    currency: string;
    totalPrice: number;
    primaryTaxPrice: number;
    order?: {
      __typename: 'Order';
      id: string;
      customerId: string;
      dropId: string;
      orderType: OrderType;
      currency: string;
      totalPrice: number;
      primaryTaxPrice: number;
      operation: {
        __typename: 'Operation';
        id: string;
        option?:
          | { __typename: 'ContractDeployed' }
          | { __typename: 'ItemCreated' }
          | { __typename: 'ItemMinted' }
          | { __typename: 'NftTransferred'; nftIds: Array<string> }
          | { __typename: 'WalletSetup' }
          | null;
      };
    } | null;
  };
};

export type ItemTransferByGiftCodeMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  dropId: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;

export type ItemTransferByGiftCodeMutation = {
  __typename: 'Mutation';
  itemTransferByGiftCode: {
    __typename: 'Operation';
    id: string;
    option?:
      | { __typename: 'ContractDeployed' }
      | { __typename: 'ItemCreated' }
      | { __typename: 'ItemMinted' }
      | {
          __typename: 'NftTransferred';
          nftIds: Array<string>;
          walletId: string;
        }
      | { __typename: 'WalletSetup' }
      | null;
  };
};

export type ItemTransferByDropMutationVariables = Exact<{
  applicationId: Scalars['String']['input'];
  dropId: Scalars['String']['input'];
}>;

export type ItemTransferByDropMutation = {
  __typename: 'Mutation';
  itemTransferByDrop: {
    __typename: 'Operation';
    id: string;
    option?:
      | { __typename: 'ContractDeployed' }
      | { __typename: 'ItemCreated' }
      | { __typename: 'ItemMinted' }
      | {
          __typename: 'NftTransferred';
          nftIds: Array<string>;
          walletId: string;
        }
      | { __typename: 'WalletSetup' }
      | null;
  };
};

export type SessionCreateByDropMutationVariables = Exact<{
  dropId: Scalars['ID']['input'];
  successUrl: Scalars['String']['input'];
  cancelUrl: Scalars['String']['input'];
}>;

export type SessionCreateByDropMutation = {
  __typename: 'Mutation';
  sessionCreateByDrop: {
    __typename: 'CheckoutPayload';
    status: number;
    url: string;
  };
};

export type PingQueryVariables = Exact<{ [key: string]: never }>;

export type PingQuery = {
  __typename: 'Query';
  ping: { __typename: 'PongPayload'; status: string };
};

export type GetApplicationFeatureContentsQueryVariables = Exact<{
  applicationId: Scalars['ID']['input'];
}>;

export type GetApplicationFeatureContentsQuery = {
  __typename: 'Query';
  applicationFeatureContents: {
    __typename: 'ApplicationFeatureContentsConnection';
    totalCount: number;
    edges?: Array<{
      __typename: 'ApplicationFeatureContentEdge';
      node: {
        __typename: 'ApplicationFeatureContent';
        id: string;
        applicationId: string;
        contentType: ContentType;
        externalLink?: string | null;
        campaignSlug?: string | null;
        dropSlug?: string | null;
        startFrom?: any | null;
        expiredAt?: any | null;
        sortIndex: number;
        asset?: { __typename: 'Asset'; mimeType: string; url: string } | null;
      };
    }> | null;
    pageInfo?: {
      __typename: 'PageInfo';
      startCursor?: string | null;
      endCursor?: string | null;
    } | null;
  };
};

export type GetSchedulesMaintenanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSchedulesMaintenanceQuery = {
  __typename: 'Query';
  scheduledMaintenancesForFuture: Array<{
    __typename: 'MaintenanceSchedule';
    startAt: any;
    endAt: any;
  }>;
};

export type GetDropsQueryVariables = Exact<{
  applicationId: Scalars['String']['input'];
  distributionType: Scalars['String']['input'];
  isStartAtLessThan?: InputMaybe<Scalars['DateTime']['input']>;
  isExpiredAtGreaterThan?: InputMaybe<Scalars['DateTime']['input']>;
}>;

export type GetDropsQuery = {
  __typename: 'Query';
  drops: {
    __typename: 'DropConnection';
    edges?: Array<{
      __typename: 'DropEdge';
      node: {
        __typename: 'Drop';
        name: string;
        price?: number | null;
        distributionType: string;
        description?: string | null;
        slug: string;
        startAt?: any | null;
        expiredAt?: any | null;
        externalContentUrl?: string | null;
        thumbnailAssetUrl?: string | null;
        bannerImageAssetUrl?: string | null;
      };
    }> | null;
  };
};

export type FeatureContentItemFragment = {
  __typename: 'ApplicationFeatureContent';
  id: string;
  applicationId: string;
  contentType: ContentType;
  externalLink?: string | null;
  campaignSlug?: string | null;
  dropSlug?: string | null;
  startFrom?: any | null;
  expiredAt?: any | null;
  sortIndex: number;
  asset?: { __typename: 'Asset'; mimeType: string; url: string } | null;
};

export type DropItemFragment = {
  __typename: 'Drop';
  name: string;
  price?: number | null;
  distributionType: string;
  description?: string | null;
  slug: string;
  startAt?: any | null;
  expiredAt?: any | null;
  externalContentUrl?: string | null;
  thumbnailAssetUrl?: string | null;
  bannerImageAssetUrl?: string | null;
};

export const CampaignMissionListFragmentDoc = gql`
  fragment CampaignMissionList on CampaignMission {
    id
    isRevealed
    title
    sortIndex
    type
    achievementPoint
    startAt
    endAt
    externalContentUrl
    campaignId
    campaignMissionTwitterFollowSetting {
      screenName
    }
    campaignMissionTwitterTweetSetting {
      text
      url
      hashtags
    }
    campaignMissionTwitterRetweetSetting {
      tweetId
    }
  }
`;
export const CampaignAchievementIconListFragmentDoc = gql`
  fragment CampaignAchievementIconList on CampaignAchievementIcon {
    id
    sortIndex
    title
    description
    achievementPoint
    iconNormalImageAsset {
      url
    }
  }
`;
export const CampaignDetailFragmentDoc = gql`
  fragment CampaignDetail on Campaign {
    id
    title
    description
    rewardDescription
    achievementPointName
    achievementIconName
    startAt
    endAt
    profileImageAsset {
      url
    }
    backgroundImageAsset {
      url
    }
    bannerImageAsset {
      url
    }
    rewardImageAsset {
      url
    }
    campaignMissions {
      ...CampaignMissionList
    }
    campaignAchievementIcons {
      ...CampaignAchievementIconList
    }
  }
  ${CampaignMissionListFragmentDoc}
  ${CampaignAchievementIconListFragmentDoc}
`;
export const CampaignAchievementLogListFragmentDoc = gql`
  fragment CampaignAchievementLogList on CampaignAchievementLog {
    id
    createdAt
    achievementPoint
    campaignMissionId
  }
`;
export const CampaignExchangeableItemListFragmentDoc = gql`
  fragment CampaignExchangeableItemList on CampaignExchangeableItem {
    id
    itemId
    achievementPoint
    isExchangedByCurrentCustomer
    exchangedAtByCurrentCustomer
    bannerImageAsset {
      url
    }
    dropItems {
      id
      title
      description
      thumbnailAssetUrl
    }
    description
    title
    startAt
    endAt
    sortIndex
  }
`;
export const NftListFragmentDoc = gql`
  fragment NftList on Nft {
    id
    serialNumber
    contractName
    contractDisplayName
    updatedAt
    item {
      id
      title
      description
      thumbnailAssetUrl
    }
  }
`;
export const NftDetailFragmentDoc = gql`
  fragment NftDetail on Nft {
    id
    customerId
    serialNumber
    contractName
    contractDisplayName
    item {
      id
      title
      description
      thumbnailAssetUrl
      copyrights
      inventoryQuantity
      totalQuantity
      externalDomainViewUrl
      properties {
        name
        value
      }
      metadata {
        name
        value
      }
      unlockableContents {
        id
        unlocked
        sortIndex
        createdAt
        privateAsset {
          mimeType
          url
        }
        thumbnailPrivateAsset {
          mimeType
          url
        }
        backgroundPrivateAsset {
          mimeType
          url
        }
      }
      publicContents {
        id
        createdAt
        backgroundPublicAsset {
          mimeType
          url
        }
        publicAsset {
          mimeType
          url
        }
      }
    }
  }
`;
export const FeatureContentItemFragmentDoc = gql`
  fragment FeatureContentItem on ApplicationFeatureContent {
    id
    applicationId
    asset {
      mimeType
      url
    }
    contentType
    externalLink
    campaignSlug
    dropSlug
    startFrom
    expiredAt
    sortIndex
  }
`;
export const DropItemFragmentDoc = gql`
  fragment DropItem on Drop {
    name
    price
    distributionType
    description
    slug
    startAt
    expiredAt
    externalContentUrl
    thumbnailAssetUrl
    bannerImageAssetUrl
  }
`;
export const GetApplicationDocument = gql`
  query GetApplication($applicationId: String!) {
    application(applicationId: $applicationId) {
      name
      iconAsset {
        id
        url
      }
    }
  }
`;

export function useGetApplicationQuery(
  options: Omit<Urql.UseQueryArgs<GetApplicationQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetApplicationQuery, GetApplicationQueryVariables>({
    query: GetApplicationDocument,
    ...options,
  });
}
export const GetCampaignDetailDocument = gql`
  query GetCampaignDetail($slug: String!, $applicationId: String!) {
    campaign(slug: $slug, applicationId: $applicationId) {
      ...CampaignDetail
    }
  }
  ${CampaignDetailFragmentDoc}
`;

export function useGetCampaignDetailQuery(
  options: Omit<Urql.UseQueryArgs<GetCampaignDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetCampaignDetailQuery, GetCampaignDetailQueryVariables>(
    { query: GetCampaignDetailDocument, ...options },
  );
}
export const GetCampaignAchievementLogsDocument = gql`
  query GetCampaignAchievementLogs($slug: String!) {
    campaignAchievementLogs(slug: $slug) {
      ...CampaignAchievementLogList
    }
  }
  ${CampaignAchievementLogListFragmentDoc}
`;

export function useGetCampaignAchievementLogsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCampaignAchievementLogsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetCampaignAchievementLogsQuery,
    GetCampaignAchievementLogsQueryVariables
  >({ query: GetCampaignAchievementLogsDocument, ...options });
}
export const GetCampaignQuizDocument = gql`
  query GetCampaignQuiz($id: ID!) {
    campaignQuiz(input: { id: $id }) {
      id
      text
      commentary
      options {
        id
        text
        isCorrect
      }
      imageAsset {
        url
      }
    }
  }
`;

export function useGetCampaignQuizQuery(
  options: Omit<Urql.UseQueryArgs<GetCampaignQuizQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetCampaignQuizQuery, GetCampaignQuizQueryVariables>({
    query: GetCampaignQuizDocument,
    ...options,
  });
}
export const GetCampaignExchangeableItemsDocument = gql`
  query GetCampaignExchangeableItems($campaignId: ID!) {
    campaignExchangeableItems(filter: { campaignId: $campaignId }) {
      edges {
        node {
          ...CampaignExchangeableItemList
        }
      }
    }
  }
  ${CampaignExchangeableItemListFragmentDoc}
`;

export function useGetCampaignExchangeableItemsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetCampaignExchangeableItemsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetCampaignExchangeableItemsQuery,
    GetCampaignExchangeableItemsQueryVariables
  >({ query: GetCampaignExchangeableItemsDocument, ...options });
}
export const GetCampaignQuizzesDocument = gql`
  query GetCampaignQuizzes($missionId: ID!) {
    campaignQuizzes(input: { missionId: $missionId }) {
      id
      title
      achieved
    }
  }
`;

export function useGetCampaignQuizzesQuery(
  options: Omit<Urql.UseQueryArgs<GetCampaignQuizzesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    GetCampaignQuizzesQuery,
    GetCampaignQuizzesQueryVariables
  >({ query: GetCampaignQuizzesDocument, ...options });
}
export const AchieveCampaignQuizDocument = gql`
  mutation achieveCampaignQuiz($id: ID!, $optionId: ID!) {
    achieveCampaignQuiz(input: { id: $id, quizOptionId: $optionId }) {
      id
      achieved
    }
  }
`;

export function useAchieveCampaignQuizMutation() {
  return Urql.useMutation<
    AchieveCampaignQuizMutation,
    AchieveCampaignQuizMutationVariables
  >(AchieveCampaignQuizDocument);
}
export const AchieveCampaignMissionByMissionIdDocument = gql`
  mutation achieveCampaignMissionByMissionId(
    $applicationId: ID!
    $missionId: ID!
  ) {
    achieveCampaignMissionByMissionId(
      campaignMissionId: $missionId
      applicationId: $applicationId
    ) {
      ...CampaignAchievementLogList
    }
  }
  ${CampaignAchievementLogListFragmentDoc}
`;

export function useAchieveCampaignMissionByMissionIdMutation() {
  return Urql.useMutation<
    AchieveCampaignMissionByMissionIdMutation,
    AchieveCampaignMissionByMissionIdMutationVariables
  >(AchieveCampaignMissionByMissionIdDocument);
}
export const GetItemExchangeLogsPointSumDocument = gql`
  query GetItemExchangeLogsPointSum($campaignId: ID!) {
    itemExchangeLogsPointSum(campaignId: $campaignId) {
      amount
    }
  }
`;

export function useGetItemExchangeLogsPointSumQuery(
  options: Omit<
    Urql.UseQueryArgs<GetItemExchangeLogsPointSumQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetItemExchangeLogsPointSumQuery,
    GetItemExchangeLogsPointSumQueryVariables
  >({ query: GetItemExchangeLogsPointSumDocument, ...options });
}
export const AchieveCampaignMissionDocument = gql`
  mutation achieveCampaignMission(
    $applicationId: ID!
    $campaignId: ID!
    $achievementCode: String!
  ) {
    achieveCampaignMission(
      applicationId: $applicationId
      campaignId: $campaignId
      achievementCode: $achievementCode
    ) {
      ...CampaignAchievementLogList
    }
  }
  ${CampaignAchievementLogListFragmentDoc}
`;

export function useAchieveCampaignMissionMutation() {
  return Urql.useMutation<
    AchieveCampaignMissionMutation,
    AchieveCampaignMissionMutationVariables
  >(AchieveCampaignMissionDocument);
}
export const AssignExchangeableItemDocument = gql`
  mutation assignExchangeableItem(
    $applicationId: ID!
    $campaignExchangeableItemId: ID!
  ) {
    assignExchangeableItemToCustomer(
      applicationId: $applicationId
      campaignExchangeableItemId: $campaignExchangeableItemId
    ) {
      id
      achievementPoint
      dropId
      itemId
    }
  }
`;

export function useAssignExchangeableItemMutation() {
  return Urql.useMutation<
    AssignExchangeableItemMutation,
    AssignExchangeableItemMutationVariables
  >(AssignExchangeableItemDocument);
}
export const GetNftsDocument = gql`
  query GetNfts(
    $applicationId: ID
    $after: String
    $before: String
    $first: Int
    $last: Int
    $offset: Int
  ) {
    myNfts(
      after: $after
      before: $before
      first: $first
      last: $last
      offset: $offset
      filter: { isIncludeNftSyncing: true, applicationId: $applicationId }
      orderBy: { updatedAt: "desc" }
    ) {
      edges {
        node {
          ...NftList
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${NftListFragmentDoc}
`;

export function useGetNftsQuery(
  options?: Omit<Urql.UseQueryArgs<GetNftsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetNftsQuery, GetNftsQueryVariables>({
    query: GetNftsDocument,
    ...options,
  });
}
export const GetNftDocument = gql`
  query GetNft($id: String!) {
    nft(id: $id) {
      ...NftList
    }
  }
  ${NftListFragmentDoc}
`;

export function useGetNftQuery(
  options: Omit<Urql.UseQueryArgs<GetNftQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetNftQuery, GetNftQueryVariables>({
    query: GetNftDocument,
    ...options,
  });
}
export const GetMyNftDetailDocument = gql`
  query GetMyNftDetail($applicationId: ID, $id: ID!) {
    myNfts(
      filter: {
        id: $id
        isIncludeNftSyncing: true
        applicationId: $applicationId
      }
    ) {
      edges {
        node {
          ...NftDetail
        }
      }
    }
  }
  ${NftDetailFragmentDoc}
`;

export function useGetMyNftDetailQuery(
  options: Omit<Urql.UseQueryArgs<GetMyNftDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetMyNftDetailQuery, GetMyNftDetailQueryVariables>({
    query: GetMyNftDetailDocument,
    ...options,
  });
}
export const GetNftDetailDocument = gql`
  query GetNftDetail($applicationId: ID, $id: ID!) {
    nfts(
      filter: {
        id: $id
        isIncludeNftSyncing: true
        applicationId: $applicationId
      }
    ) {
      edges {
        node {
          ...NftDetail
        }
      }
    }
  }
  ${NftDetailFragmentDoc}
`;

export function useGetNftDetailQuery(
  options: Omit<Urql.UseQueryArgs<GetNftDetailQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetNftDetailQuery, GetNftDetailQueryVariables>({
    query: GetNftDetailDocument,
    ...options,
  });
}
export const GetContractsHasDropDocument = gql`
  query GetContractsHasDrop($applicationId: String!) {
    contractsHasDrop(filter: { applicationId: $applicationId }) {
      name
      displayName
      items {
        id
        dropItems {
          drop {
            ...DropItem
          }
        }
      }
    }
  }
  ${DropItemFragmentDoc}
`;

export function useGetContractsHasDropQuery(
  options: Omit<Urql.UseQueryArgs<GetContractsHasDropQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    GetContractsHasDropQuery,
    GetContractsHasDropQueryVariables
  >({ query: GetContractsHasDropDocument, ...options });
}
export const GetDropsByContractDocument = gql`
  query GetDropsByContract($applicationId: ID!, $contractName: String!) {
    contractHasDrop(
      applicationId: $applicationId
      contractName: $contractName
    ) {
      name
      displayName
      applicationId
      items {
        id
        dropItems {
          drop {
            ...DropItem
          }
        }
      }
    }
  }
  ${DropItemFragmentDoc}
`;

export function useGetDropsByContractQuery(
  options: Omit<Urql.UseQueryArgs<GetDropsByContractQueryVariables>, 'query'>,
) {
  return Urql.useQuery<
    GetDropsByContractQuery,
    GetDropsByContractQueryVariables
  >({ query: GetDropsByContractDocument, ...options });
}
export const GetCustomerDocument = gql`
  query getCustomer($customerId: String!) {
    customer(id: $customerId) {
      id
      email
      nickName
      profileIconUrl
    }
  }
`;

export function useGetCustomerQuery(
  options: Omit<Urql.UseQueryArgs<GetCustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetCustomerQuery, GetCustomerQueryVariables>({
    query: GetCustomerDocument,
    ...options,
  });
}
export const IsExistCustomerDocument = gql`
  query isExistCustomer($applicationId: String) {
    existCustomer(applicationId: $applicationId) {
      exist
    }
  }
`;

export function useIsExistCustomerQuery(
  options?: Omit<Urql.UseQueryArgs<IsExistCustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<IsExistCustomerQuery, IsExistCustomerQueryVariables>({
    query: IsExistCustomerDocument,
    ...options,
  });
}
export const GetMyCustomerDocument = gql`
  query GetMyCustomer($applicationId: String) {
    myCustomer(applicationId: $applicationId) {
      id
      email
      nickName
      profileIconUrl
      customerIconId
      wallet {
        id
        address
      }
    }
  }
`;

export function useGetMyCustomerQuery(
  options?: Omit<Urql.UseQueryArgs<GetMyCustomerQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetMyCustomerQuery, GetMyCustomerQueryVariables>({
    query: GetMyCustomerDocument,
    ...options,
  });
}
export const GetCustomerIconsDocument = gql`
  query GetCustomerIcons {
    customerIcons {
      id
      assetUrl
    }
  }
`;

export function useGetCustomerIconsQuery(
  options?: Omit<Urql.UseQueryArgs<GetCustomerIconsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetCustomerIconsQuery, GetCustomerIconsQueryVariables>({
    query: GetCustomerIconsDocument,
    ...options,
  });
}
export const MyWalletDocument = gql`
  query MyWallet {
    myWallet {
      id
      address
    }
  }
`;

export function useMyWalletQuery(
  options?: Omit<Urql.UseQueryArgs<MyWalletQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyWalletQuery, MyWalletQueryVariables>({
    query: MyWalletDocument,
    ...options,
  });
}
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      id
      email
    }
  }
`;

export function useSignUpMutation() {
  return Urql.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
  );
}
export const SignInWithEmailLinkDocument = gql`
  mutation SignInWithEmailLink(
    $email: String!
    $reCaptchaToken: String!
    $callbackUrl: String!
    $applicationId: String
  ) {
    signInWithEmailLink(
      email: $email
      reCaptchaToken: $reCaptchaToken
      callbackUrl: $callbackUrl
      applicationId: $applicationId
    ) {
      email
    }
  }
`;

export function useSignInWithEmailLinkMutation() {
  return Urql.useMutation<
    SignInWithEmailLinkMutation,
    SignInWithEmailLinkMutationVariables
  >(SignInWithEmailLinkDocument);
}
export const CustomerProfileUpdateDocument = gql`
  mutation customerProfileUpdate($customerIconId: String!, $nickName: String!) {
    customerProfileUpdate(
      customerIconId: $customerIconId
      nickName: $nickName
    ) {
      id
    }
  }
`;

export function useCustomerProfileUpdateMutation() {
  return Urql.useMutation<
    CustomerProfileUpdateMutation,
    CustomerProfileUpdateMutationVariables
  >(CustomerProfileUpdateDocument);
}
export const RequestChangeEmailDocument = gql`
  mutation requestChangeEmail($newEmail: String!, $callbackUrl: String!) {
    requestChangeEmail(newEmail: $newEmail, callbackUrl: $callbackUrl) {
      email
      newEmail
      requestStatus
    }
  }
`;

export function useRequestChangeEmailMutation() {
  return Urql.useMutation<
    RequestChangeEmailMutation,
    RequestChangeEmailMutationVariables
  >(RequestChangeEmailDocument);
}
export const VerifyChangeEmailDocument = gql`
  mutation verifyChangeEmail($tokenId: String!, $callbackUrl: String!) {
    verifyChangeEmail(tokenId: $tokenId, callbackUrl: $callbackUrl) {
      email
      newEmail
      requestStatus
    }
  }
`;

export function useVerifyChangeEmailMutation() {
  return Urql.useMutation<
    VerifyChangeEmailMutation,
    VerifyChangeEmailMutationVariables
  >(VerifyChangeEmailDocument);
}
export const ConfirmUpdateEmailDocument = gql`
  mutation confirmUpdateEmail($tokenId: String!) {
    confirmUpdateEmail(tokenId: $tokenId) {
      email
      newEmail
      requestStatus
    }
  }
`;

export function useConfirmUpdateEmailMutation() {
  return Urql.useMutation<
    ConfirmUpdateEmailMutation,
    ConfirmUpdateEmailMutationVariables
  >(ConfirmUpdateEmailDocument);
}
export const GetDropDocument = gql`
  query GetDrop($slug: String, $dropId: String, $applicationId: String!) {
    drop(applicationId: $applicationId, id: $dropId, slug: $slug) {
      id
      name
      slug
      distributionType
      thumbnailAssetUrl
      price
      inventoryQuantity
      totalQuantity
      acquisitionLimitCount
      description
      notice
      expiredAt
      items {
        edges {
          node {
            id
            title
            thumbnailAssetUrl
          }
        }
      }
    }
  }
`;

export function useGetDropQuery(
  options: Omit<Urql.UseQueryArgs<GetDropQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetDropQuery, GetDropQueryVariables>({
    query: GetDropDocument,
    ...options,
  });
}
export const CheckoutDocument = gql`
  query Checkout($checkoutId: String!) {
    checkout(id: $checkoutId) {
      id
      customerId
      dropId
      status
      declineCode
      message
      currency
      totalPrice
      primaryTaxPrice
      order {
        id
        customerId
        dropId
        orderType
        currency
        totalPrice
        primaryTaxPrice
        operation {
          id
          option {
            __typename
            ... on NftTransferred {
              nftIds
            }
          }
        }
      }
    }
  }
`;

export function useCheckoutQuery(
  options: Omit<Urql.UseQueryArgs<CheckoutQueryVariables>, 'query'>,
) {
  return Urql.useQuery<CheckoutQuery, CheckoutQueryVariables>({
    query: CheckoutDocument,
    ...options,
  });
}
export const ItemTransferByGiftCodeDocument = gql`
  mutation ItemTransferByGiftCode(
    $applicationId: String!
    $dropId: String!
    $code: String!
  ) {
    itemTransferByGiftCode(
      applicationId: $applicationId
      dropId: $dropId
      giftCode: $code
    ) {
      id
      option {
        __typename
        ... on NftTransferred {
          nftIds
          walletId
        }
      }
    }
  }
`;

export function useItemTransferByGiftCodeMutation() {
  return Urql.useMutation<
    ItemTransferByGiftCodeMutation,
    ItemTransferByGiftCodeMutationVariables
  >(ItemTransferByGiftCodeDocument);
}
export const ItemTransferByDropDocument = gql`
  mutation ItemTransferByDrop($applicationId: String!, $dropId: String!) {
    itemTransferByDrop(applicationId: $applicationId, dropId: $dropId) {
      id
      option {
        __typename
        ... on NftTransferred {
          nftIds
          walletId
        }
      }
    }
  }
`;

export function useItemTransferByDropMutation() {
  return Urql.useMutation<
    ItemTransferByDropMutation,
    ItemTransferByDropMutationVariables
  >(ItemTransferByDropDocument);
}
export const SessionCreateByDropDocument = gql`
  mutation SessionCreateByDrop(
    $dropId: ID!
    $successUrl: String!
    $cancelUrl: String!
  ) {
    sessionCreateByDrop(
      input: {
        dropId: $dropId
        purchaseSuccessUrl: $successUrl
        purchaseCancelUrl: $cancelUrl
      }
    ) {
      status
      url
    }
  }
`;

export function useSessionCreateByDropMutation() {
  return Urql.useMutation<
    SessionCreateByDropMutation,
    SessionCreateByDropMutationVariables
  >(SessionCreateByDropDocument);
}
export const PingDocument = gql`
  query Ping {
    ping {
      status
    }
  }
`;

export function usePingQuery(
  options?: Omit<Urql.UseQueryArgs<PingQueryVariables>, 'query'>,
) {
  return Urql.useQuery<PingQuery, PingQueryVariables>({
    query: PingDocument,
    ...options,
  });
}
export const GetApplicationFeatureContentsDocument = gql`
  query GetApplicationFeatureContents($applicationId: ID!) {
    applicationFeatureContents(
      filter: { applicationId: $applicationId }
      orderBy: { sortIndex: "asc" }
    ) {
      edges {
        node {
          ...FeatureContentItem
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
  ${FeatureContentItemFragmentDoc}
`;

export function useGetApplicationFeatureContentsQuery(
  options: Omit<
    Urql.UseQueryArgs<GetApplicationFeatureContentsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetApplicationFeatureContentsQuery,
    GetApplicationFeatureContentsQueryVariables
  >({ query: GetApplicationFeatureContentsDocument, ...options });
}
export const GetSchedulesMaintenanceDocument = gql`
  query GetSchedulesMaintenance {
    scheduledMaintenancesForFuture {
      startAt
      endAt
    }
  }
`;

export function useGetSchedulesMaintenanceQuery(
  options?: Omit<
    Urql.UseQueryArgs<GetSchedulesMaintenanceQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    GetSchedulesMaintenanceQuery,
    GetSchedulesMaintenanceQueryVariables
  >({ query: GetSchedulesMaintenanceDocument, ...options });
}
export const GetDropsDocument = gql`
  query GetDrops(
    $applicationId: String!
    $distributionType: String!
    $isStartAtLessThan: DateTime
    $isExpiredAtGreaterThan: DateTime
  ) {
    drops(
      filter: {
        applicationId: $applicationId
        distributionType: $distributionType
        isStartAtLessThan: $isStartAtLessThan
        isExpiredAtGreaterThan: $isExpiredAtGreaterThan
      }
      first: 50
      orderBy: { startAt: "desc" }
    ) {
      edges {
        node {
          ...DropItem
        }
      }
    }
  }
  ${DropItemFragmentDoc}
`;

export function useGetDropsQuery(
  options: Omit<Urql.UseQueryArgs<GetDropsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GetDropsQuery, GetDropsQueryVariables>({
    query: GetDropsDocument,
    ...options,
  });
}
