import './styles.css';
import 'keen-slider/keen-slider.min.css';

import {
  createUrqlClient,
  CustomerErrorType,
  EnvErrorType,
  initAuth,
  pickGraphqlCode,
} from '@collection-platform-frontend/api';
import {
  DarkModeProvider,
  GoogleTag,
  GoogleTagId,
  GoogleTagManager,
  GoogleTagManagerId,
} from '@collection-platform-frontend/shared';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import { CombinedError, Provider } from 'urql';

import { authOption } from '../libs/auth-option';

const gtmId = process.env
  .NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as GoogleTagManagerId;
const gtagId = process.env.NEXT_PUBLIC_GOOGLE_TAG_ID as GoogleTagId;

initAuth(authOption);

function MyApp({ Component, pageProps }: AppProps) {
  const { replace } = useRouter();

  const onError = useCallback(
    (e: CombinedError) => {
      const errorCode = pickGraphqlCode(e);
      switch (errorCode) {
        case EnvErrorType.UNDER_MAINTENANCE: {
          replace('/maintenance');
          break;
        }
        case CustomerErrorType.FORBIDDEN: {
          replace('/auth/signout');
          break;
        }
      }
    },
    [replace],
  );

  const client = createUrqlClient(onError);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <DefaultSeo title="Anique Wallet" />
      <GoogleTagManager googleTagManagerId={gtmId} />
      <GoogleTag googleTagId={gtagId} />
      <Provider value={client}>
        <DarkModeProvider>
          <Component {...pageProps} />
          <Toaster />
        </DarkModeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
