import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useInView } from 'react-cool-inview';

import {
  GetNftsQuery,
  NftListFragment,
  PageInfo,
  useGetNftsQuery,
} from '../graphql/schema';

export type NftListItem = Partial<NftListFragment>;

const range = (start: number, end: number) =>
  [...Array(end - start + 1)].map((_, i) => start + i);

const createDummyNfts = (num: number): NftListItem[] => {
  const count = range(0, num - 1);
  return count.map((_v, i) => {
    return {
      __typename: 'Nft',
      contractName: '__dummy__',
      serialNumber: 0,
      item: {
        __typename: 'Item',
        id: '__dummy__',
        title: '__dummy__',
        description: '__dummy__',
        thumbnailAssetUrl: '',
      },
    };
  });
};

const getNfts = (data?: GetNftsQuery) => {
  const myNfts = data?.myNfts.edges?.map(({ node }) => node);
  if (myNfts && myNfts.length > 0) {
    return myNfts;
  }

  return createDummyNfts(6);
};

interface CollectionResult<T extends HTMLElement | null = HTMLElement> {
  fetching: boolean;
  nfts: Partial<NftListFragment>[];
  numOfNfts: number;
  pageInfo?: Partial<PageInfo> | null;
  observe: (element?: T | null) => void;
}

export const useCurrentCollection = (
  applicationId?: string,
  fetchCount = 10,
): CollectionResult => {
  const { isReady } = useRouter();
  const [count, setCount] = useState<number>(fetchCount);

  const { observe, inView } = useInView({
    rootMargin: '50px 0px',
    onEnter: ({ unobserve }) => {
      unobserve();
    },
  });

  const [{ data, fetching }] = useGetNftsQuery({
    pause: !isReady,
    variables: {
      applicationId,
      first: count,
    },
    requestPolicy: 'network-only',
  });
  const pageInfo = data?.myNfts?.pageInfo;
  const numOfNfts = data?.myNfts?.edges?.length ?? 0;
  const nfts = getNfts(data);

  useEffect(() => {
    if (!fetching && inView && pageInfo?.hasNextPage) {
      setCount(count + fetchCount);
      observe();
    }
  }, [pageInfo, observe, inView, count, fetchCount, fetching]);

  return {
    fetching,
    nfts,
    numOfNfts,
    pageInfo,
    observe,
  };
};
